<template>
  <v-app>
    <v-app-bar app color="white">
      <div class="d-flex align-center">
        <a href="/">
          <v-img
            alt="Hedelse Banden Service"
            class="shrink mr-2"
            contain
            src="./assets/img/logo_hbs.jpg"
            width="200"
        /></a>
      </div>
    </v-app-bar>
    <v-main>
      <marquee-text
        v-if="informationalText != ''"
        :duration="speed"
        :repeat="15"
        class="py-2 announcementBar"
      >
        <label style="color: white; margin-right: 5px;"
          ><v-icon color="white">mdi-information-outline</v-icon>
          {{ informationalText }}
        </label>
      </marquee-text>
      <marquee-text :duration="80" :repeat="3" class="py-2 infobar">
        <label style="color: white; margin-right: 5px;"
          ><v-icon color="white">mdi-alert-outline</v-icon> Controleer de
          polisvoorwaarden van uw autoverzekering. In de meeste polisvoorwaarden
          is direct, danwel indirect, opgenomen dat uw auto ten alle tijde
          APK-waardig moet zijn als u deel wilt nemen aan het verkeer op de
          openbare weg. Banden met minder dan 1,6mm profieldiepte, scheuren,
          bulten, etc zijn niet APK-waardig, met als gevolg dat ook uw auto niet
          APK-waardig is. De verzekering hoeft u dan bij calamiteiten niet uit
          te keren. U bent dan aansprakelijk voor alle schade en kosten.
        </label>
      </marquee-text>
      <router-view />
    </v-main>
    <v-footer color="#E57917">
      <v-row justify="center" no-gutters v-if="size > 600">
        <div class="pt-2" style="color: white; float: right !important;">
          <strong class="float-right">Openingstijden</strong>
          <br />
          <i class="float-right"
            >Maandag: 09 <sup>00</sup> tot 17 <sup>00</sup></i
          >
          <br />
          <i class="float-right"
            >Dinsdag: 09 <sup>00</sup> tot 17 <sup>00</sup></i
          >
          <br />
          <i class="float-right"
            >Woensdag: 09 <sup>00</sup> tot 17 <sup>00</sup></i
          >
          <br />
          <i class="float-right"
            >Donderdag: 09 <sup>00</sup> tot 17 <sup>00</sup></i
          >
          <br />
          <i class="float-right"
            >Vrijdag: 09 <sup>00</sup> tot 17 <sup>00</sup></i
          >
          <br />
          <i class="float-right"
            >Zaterdag: 09 <sup>00</sup> tot 13 <sup>30</sup></i
          >
          <br />
          <i class="float-right">Zondag: Gesloten</i>
          <br />
          <i class="float-right"
            ><strong
              >Lunchpauze tussen 12 <sup>30</sup> en 13 <sup>00</sup></strong
            ></i
          >
        </div>
        <v-divider vertical class="mx-2" inset color="white" />
        <div
          class="pt-2"
          style="color: white; float: left !important; width: 224px !important"
        >
          <strong class="float-left">Contactgegevens</strong>
          <br />
          <i class="float-left">De Hootkamp 3a</i>
          <br />
          <i class="float-left">5321JT Hedel</i>
          <br />
          <i class="float-left">06-13 53 98 91</i>
          <br />
          <a
            class="text-decoration-none  white--text"
            href="mailto:hedelsebandenservice@kpnmail.nl"
            target="_blank"
            ><i>hedelsebandenservice@kpnmail.nl</i></a
          >
        </div>
        <v-col class="py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} —
          <strong>Hedelse Banden Service</strong>
          <br />
          Made by:
          <a href="https://bartverm.nl" target="_blank" style="color: white"
            ><i>Bart Vermeulen</i></a
          >
        </v-col>
      </v-row>
      <div v-else class="mx-auto">
        <div class="pt-2" style="color: white; text-align: center !important;">
          <strong>Openingstijden</strong>
          <br />
          <i>Maandag: 09 <sup>00</sup> tot 17 <sup>00</sup></i>
          <br />
          <i>Dinsdag: 09 <sup>00</sup> tot 17 <sup>00</sup></i>
          <br />
          <i>Woensdag: 09 <sup>00</sup> tot 17 <sup>00</sup></i>
          <br />
          <i>Donderdag: 09 <sup>00</sup> tot 17 <sup>00</sup></i>
          <br />
          <i>Vrijdag: 09 <sup>00</sup> tot 17 <sup>00</sup></i>
          <br />
          <i>Zaterdag: 09 <sup>00</sup> tot 13 <sup>30</sup></i>
          <br />
          <i>Zondag: Gesloten</i>
          <br />
          <i
            ><strong
              >Lunchpauze tussen 12 <sup>30</sup> en 13 <sup>00</sup></strong
            ></i
          >
        </div>
        <br />
        <v-divider class="mx-2" color="white" />
        <br />
        <div class="pt-2" style="color: white; text-align: center !important;">
          <strong>Contactgegevens</strong>
          <br />
          <i>De Hootkamp 3a</i>
          <br />
          <i>5321JT Hedel</i>
          <br />
          <i>06-13 53 98 91</i>
          <br />
          <a
            class="text-decoration-none white--text"
            href="mailto:hedelsebandenservice@kpnmail.nl"
            target="_blank"
            ><i>hedelsebandenservice@kpnmail.nl</i></a
          >
        </div>
        <v-col class="py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} —
          <strong>Hedelse Banden Service</strong>
          <br />
          Made by:
          <a href="https://bartverm.nl" target="_blank" style="color: white"
            ><i>Bart Vermeulen</i></a
          >
        </v-col>
      </div>
    </v-footer>
    <div
      class="white--text pl-2 pr-2"
      style="background-color: #193B85; font-size: 15px"
    >
      *Als gevolg van de door ons gehanteerde hoge eisen met betrekking tot de
      kwaliteitscontrole zal de geleverde band op het moment van levering de
      navolgende gebreken niet vertonen: Scheuren, uitstulpingen, beschadigingen
      welke de betrouwbaarheid en de veiligheid van de band nadelig beïnvloeden,
      gecupt profiel. Blijkt bij gebruik van de band dat, ondanks onze
      kwaliteitscontrole, een of meerdere van de hiervoor vermelde gebreken zich
      binnen twee dagen na levering openbaren, dan zal de band geheel kostenloos
      worden vervangen.
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    links: ["Home", "About Us", "Team", "Services", "Blog", "Contact Us"],
    size: window.outerWidth,
    informationalText: "",
    speed: 0,
  }),
  created() {
    window.addEventListener("resize", () => {
      this.size = window.innerWidth;
    });
    this.$axios.get("text.txt").then((result) => {
      this.informationalText = result.data;
    });
    this.$axios.get("speed.txt").then((result) => {
      this.speed = result.data;
    });
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.infobar {
  background-color: #c62828 !important;
}

.announcementBar {
  background-color: #26c6da !important;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: grey;
}

body::-webkit-scrollbar-thumb {
  background-color: darkslategrey;
}

#app {
  font-size: 18px;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto !important;
}
</style>
