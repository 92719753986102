import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import MarqueeText from 'vue-marquee-text-component'
import VueNumber from 'vue-number-animation'
import axios from 'axios'


Vue.use(VueNumber)
Vue.component('marquee-text', MarqueeText)

Vue.prototype.$axios = axios;



Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
