import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../components/index.vue')
  },
  {
    path: '/login',
    name: 'Inloggen',
    component: () => import('../components/login.vue')
  },
  {
    path: '/stock',
    name: 'Voorraad',
    component: () => import('../components/stock.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../components/faq.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../components/404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
